import csvParser from "../csvParser";
import transformToAnimal from "../transformToAnimal";

const AnimalsResource = require("../../resources/Animals.csv");

export default () => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", AnimalsResource);
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const csvContent = csvParser(xhr.response);
        const knownAnimals = csvContent.reduce(transformToAnimal(), new Map());
        resolve(knownAnimals);
      } else {
        reject(xhr.statusText);
      }
    };
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send();
  });
};
