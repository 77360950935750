export default () => (mapStorage, rowData) => {
  const storage = mapStorage;
  const animal = {
    id: rowData[0],
    name: rowData[0],
    type: "Tier",
    animal: {
      index: {
        total: rowData[1],
        good: rowData[2],
        slaughter: rowData[3]
      },
      price: {
        purchase: rowData[4],
        price: rowData[6]
      },
      slaughter: {
        value: rowData[7],
        duration: rowData[8]
      },
      goods: {
        numberOfGoods: rowData[9],
        quantityOfGoods: rowData[10]
      },
      breeding: {
        price: rowData[5],
        durationOfBreeding: rowData[11],
        growthDuration: rowData[12],
        growthDurationWithBonus: rowData[13],
        growthBonus: rowData[14],
        growthCount: rowData[15]
      }
    },
    ingredients: null
  };
  return storage.set(animal.name, animal);
};
