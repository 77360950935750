import React from "react";
import PropTypes from "prop-types";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Detail from "./Detail";

const Statistic = ({
  profitPerHour,
  totalProfitPerHour,
  totalTime,
  experiencePerHour
}) => {
  const strucutre = {
    name: "Statistik",
    nameIcon: null,
    defaultDisplay: false,
    rows: [
      {
        name: "Gewinn pro Produkt",
        key: `${profitPerHour} Taler / h `
      },
      {
        name: "Gewinn pro Produktkette (10 Stück)",
        key: `${totalProfitPerHour} Taler / h`
      },
      {
        name: "Gesamt Herstellungsdauer",
        key: totalTime
      },
      {
        name: "Erfahrungspunkte pro Stunde",
        key: `${experiencePerHour}`,
        keyIcon: faStar
      }
    ]
  };

  return <Detail {...strucutre} key={strucutre.name} />;
};

Statistic.propTypes = {
  profitPerHour: PropTypes.string.isRequired,
  totalProfitPerHour: PropTypes.string.isRequired,
  totalTime: PropTypes.string.isRequired,
  experiencePerHour: PropTypes.string.isRequired
};

export default Statistic;
