import React from "react";
import PropTypes from "prop-types";
import Detail from "./Detail";

const Guild = ({ points, pointsPerHour }) => {
  const structure = {
    name: "Gilde",
    nameIcon: null,
    defaultDisplay: false,
    rows: [
      {
        name: "Gildenpunkte (GP)",
        key: `${points} GP`
      },
      {
        name: "Gildenpunkte pro Stunde",
        key: `${pointsPerHour} GP / h`
      }
    ]
  };

  return <Detail {...structure} key={structure.name} />;
};

Guild.propTypes = {
  points: PropTypes.string.isRequired,
  pointsPerHour: PropTypes.string.isRequired
};

export default Guild;
