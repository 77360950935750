import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getItemList } from "../../service/Items";
import Recipe from "./Recipe";

const ListOfItems = props => {
  const [items, setItems] = useState(null);

  const {
    setGlobalSearchValue,
    match: {
      params: { searchterm }
    }
  } = props;

  useEffect(() => {
    setGlobalSearchValue(searchterm);
    getItemList(searchterm)
      .then(result => {
        // console.log("result from service", result);
        setItems(result);
      })
      .catch(error => {
        console.error("an error is occured", error);
      });
  }, [searchterm]);

  return (
    <div className="container">
      <div className="subtitle  has-text-grey-lighter">
        Suche nach &quot;{searchterm}&quot;{" "}
        {items !== null ? (
          <span>ergab {Array.from(items.keys()).length} Treffer </span>
        ) : (
          ""
        )}
      </div>
      <ul className="menu-list">
        {items === null ? (
          <li key="none" id="none">
            kein Treffer
          </li>
        ) : (
          Array.from(items.keys()).map(element => (
            <Recipe {...items.get(element)} key={element} />
          ))
        )}
      </ul>
    </div>
  );
};

ListOfItems.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      searchterm: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  setGlobalSearchValue: PropTypes.func.isRequired
};

export default ListOfItems;
