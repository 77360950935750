import React, { useState } from "react";
import "bulma/css/bulma.css";
import { Switch, Route } from "react-router-dom";
import SearchFieldRx from "./Search/SearchFieldRx";
import ListOfItems from "./List/ListOfItems";
import ItemDetail from "./Detail/ItemDetail";
import Logo from "../resources/farmies3.png";

const App = () => {
  const [globalSearchValue, setGlobalSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  return (
    <>
      <nav className="navbar container">
        <div className="navbar-brand">
          <a href="/">
            <img
              className="navbar-item"
              src={Logo}
              alt="Logo"
              height="28"
              weidth="46"
            />
          </a>
        </div>
        <div id="navbarTop" className="navbar-menu is-active">
          <div className="navbar-start">
            <div className="navbar-item">
              <SearchFieldRx
                setGlobalSearchValue={setGlobalSearchValue}
                setSuggestions={setSuggestions}
                suggestions={suggestions}
                globalSearchValue={globalSearchValue}
              />
            </div>
          </div>
        </div>
      </nav>
      <div className="container">
        <Switch>
          <Route
            path="/items/:searchterm"
            render={props => (
              <ListOfItems
                {...props}
                setGlobalSearchValue={setGlobalSearchValue}
              />
            )}
          />
          <Route
            path="/itemdetail/:itemid"
            render={props => (
              <ItemDetail
                {...props}
                setGlobalSearchValue={setGlobalSearchValue}
              />
            )}
          />
          <Route>
            <section className="section">
              <div className="container">
                <div className="title">Little Farmies Mobil Wiki</div>
                <div className="subtitle">
                  Nachschlagewerk für Rezepte, Tiere und Gebäude für das Spiel
                  "My Little Farmies Mobile" von Upjers
                </div>
                <div className="content notifictation">
                  Gebt oben im Suchfenster ein Stichwort (z.B. Schaf) und ihr
                  bekommt eine Liste aller Tier, Rezepte und Gebäude und ihr
                  bekommt eine entsprechende Liste angezeigt.
                </div>
                <div className="content">
                  <strong>letzte Neuerung:</strong>
                  <ul className="menulist">
                    <li>
                      Navigation: Man kann innerhalb von den Rezepten / Pflanzen
                      / Tieren hin und her navigieren
                    </li>
                    <li>
                      Daten: Hereford-Rind und Magarete wurden hinzugefügt
                    </li>
                  </ul>
                </div>
                <div className="content">
                  <strong>Geplante Features:</strong>
                  <ul className="menulist">
                    <li>Suche nach Gebäuden</li>
                    <li>Welches Rezept sich am meisten lohnt</li>
                    <li>Welches Tier sich am meisten lohnt</li>
                    <li>Welches Pflanze sich am meisten lohnt</li>
                  </ul>
                </div>
              </div>
            </section>
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default App;
