const getUniqueItem = (element, count = "") => {
  const displayCount = count !== "" ? `${count}x ` : "";
  const building = element?.recipe?.building.trim() ?? "";
  const addBuiling = building !== "" ? ` (${building})` : building;
  const name = `${displayCount}${element.name}${addBuiling}`;
  return {
    name,
    link: element.id
  };
};

const getName = item => {
  const displayCount = item.count !== undefined ? `${item.count}x ` : "";
  return `${displayCount}${item.name}`;
};

export default (knownItems, forItems) => {
  if (forItems.size === 0) {
    return [{ name: "---" }];
  }
  // console.log("findRequiredIngredients", knownItems, forItems);

  return [...forItems.values()].map(cur => {
    // console.log("element", cur);
    if (knownItems.has(cur.name)) {
      const knownItem = knownItems.get(cur.name);
      return getUniqueItem(knownItem, cur.count);
    }

    const ambiguous = [...knownItems.values()].filter(element => {
      return cur.name === element.internalName;
    });
    const name = getName(cur);
    // console.log("ambiguous", ambiguous, cur);
    if (ambiguous.length !== 0) {
      return { name, link: cur.name, ambiguous: true };
    }
    return { name };
  });
};
