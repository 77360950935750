const getType = type => {
  if (type === "Feld") return "Pflanze";
  if (type === "Tier") return "Tier";
  return "Rezept";
};
const getBuilding = type => {
  if (type === "Feld") return "Saat";
  if (type === "Tier") return "Gatter";
  return type;
};

const getPurchase = (type, purchase) => {
  if (type !== "Feld") return "";
  return purchase;
};

const getSeed = (element, type) => {
  // console.log("seed", type);
  if (type !== "Pflanze") {
    return null;
  }
  return {
    duration: element[2],
    // durationInclBonus: cur[3],
    quantity: element[4],
    price: element[5],
    salesPrice: element[6],
    experience: element[7],
    bonusTime: element[9],

    seed: element[10],
    harvest: element[11],
    water: element[12],
    purchase: getPurchase(element[19], element[18])
  };
};

const spliteRecipe = value => {
  const regEx = /\d[x]\s[a-zA-ZäöüÄÖUß-]{2,20}\s[a-zA-ZäöüÄÖUß-]{2,20}|\d[x]\s[a-zA-ZäöüÄÖUß-]{2,20}|[a-zA-ZäöüÄÖUß-]{2,20}/g;
  if (new RegExp(regEx).test(value)) {
    const result = [...value.matchAll(regEx)];
    return result;
  }
  return [[value]];
};

const getRecipe = (element, type) => {
  if (type === "Tier" || type === "Pflanze") {
    return null;
  }
  // console.log("recipe", element);
  return {
    duration: element[2],
    // durationInclBonus: element[3],
    quantity: element[4],
    price: element[5],
    salesPrice: element[6],
    experience: element[7],
    building: getBuilding(element[19])
  };
};

const getId = (element, notUniqueItems) => {
  if (notUniqueItems) return element[1];

  const recipe = spliteRecipe(element[18]).reduce((prev, next) => {
    const match = next[0].match(/[a-zA-ZäöüÄÖUß-]{2,20}/);
    if (match === null) {
      return prev;
    }
    return prev + match[0].toString();
  }, "");
  return `${element[1].trim()}+${recipe.trim()}`;
};

const isNotUnqie = (element, notUniqueItems) =>
  !notUniqueItems.includes(element[1]);

const getName = (element, notUniqueItems) => {
  if (notUniqueItems) return element[1];
  // console.log("getName", element[1], element[18]);
  const recipe = spliteRecipe(element[18]).reduce((prev, next) => {
    const match = next[0].match(/[a-zA-ZäöüÄÖUß-]{2,20}/);
    // console.log("match", next[0], match);
    if (match === null) {
      return prev;
    }
    return prev + match[0].toString();
  }, "");
  return `${element[1].trim()} vom ${recipe.trim()}`;
};

const getIngredients = (rowIngredients, type) => {
  if (type === "Tier" || type === "Pflanze") {
    return null;
  }
  const value = rowIngredients[18];
  // console.log("value", value);
  const regEx = /\d[x]\s[a-zA-ZäöüÄÖUß-]{2,20}\s[a-zA-ZäöüÄÖUß-]{2,20}|\d[x]\s[a-zA-ZäöüÄÖUß-]{2,20}|[a-zA-ZäöüÄÖUß-]{2,20}/g;
  const ingredients = new Map();
  if (new RegExp(regEx).test(value)) {
    const result = [...value.matchAll(regEx)];
    // console.log("match", result[0]);
    return result.reduce((storage, element) => {
      const obj = storage;
      const regex = new RegExp(/^\d/g).test(element[0] ?? "");
      if (regex) {
        const [count, name] = element[0].split("x ");
        obj.set(name, { count, name });
      } else {
        obj.set(element[0], { name: element[0] });
      }
      return obj;
    }, ingredients);
  }
  return ingredients;
};

export default notUniqueItems => (prev, cur) => {
  const type = getType(cur[19]);
  const notUnqie = isNotUnqie(cur, notUniqueItems);
  const recipe = {
    id: getId(cur, notUnqie),
    name: getName(cur, notUnqie),
    internalName: cur[1],
    type,
    guild: {
      points: cur[8],
      pointsPerHour: cur[16]
    },
    statistic: {
      profitPerHour: cur[13],
      totalProfitPerHour: cur[14],
      experiencePerHour: cur[15],
      totalTime: cur[17]
    },
    seed: getSeed(cur, type),
    recipe: getRecipe(cur, type),
    ingredients: getIngredients(cur, type)
    /*
    duration: cur[2],
    durationInclBonus: cur[3],
    quantity: cur[4],
    price: cur[5],
    salesPrice: cur[6],
    experience: cur[7],
    bonusTime: cur[9],
    seed: cur[10],
    harvest: cur[11],
    water: cur[12],
    recipe: getRecipeDetails(cur[19], cur[18]),
    purchase: getPurchase(cur[19], cur[18]),
    building: getBuilding(cur[19])
    */
  };
  // console.log(recipe);
  prev.set(recipe.id, recipe);
  return prev;
};
