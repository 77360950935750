import { isObject } from "util";
import animals from "./details/animalDetails";
import recipes from "./details/recipeDetails";
import recipeList from "./list/recipeList";
import findIngredientsOf from "./findIngredientsOf";
import findRequiredIngredients from "./findRequiredIngredients";

export const getRecipe = async filter => {
  return Promise.race([
    Promise.all([recipes(filter), animals(filter)]).then(values => {
      // console.log("detail items", values);
      let details = new Map();
      try {
        details = values.reduce((prev, cur) => {
          // console.log("map list", cur);
          cur.forEach(element => {
            if (prev.has(element.id)) {
              // console.log("to merge", element);
              const obj = prev.get(element.id);
              // console.log("to source", obj);
              const merged = Object.assign(obj, element);
              // console.log("merged", merged);
              prev.set(element.id, merged);
            } else {
              prev.set(element.id, element);
            }
          });
          return prev;
        }, details);
      } catch (error) {
        // console.log("an error is occured", error);
      }
      // console.log("merge lists", details);
      const detail = details.get(filter);
      detail.ingredientOf = findIngredientsOf(details, detail.internalName);
      if (isObject(detail.ingredients)) {
        detail.requiredIngredients = findRequiredIngredients(
          details,
          detail.ingredients
        );
      }
      // console.log("show found item", detail);
      return detail;
    }),
    new Promise((_, reject) => {
      setTimeout(reject, 800);
    })
  ]);
};
export const getItemList = async filter => {
  const list = Promise.race([
    Promise.all([recipeList(filter)]).then(values => {
      // recipe: value[0]
      const listOfItems = values.reduce((storage, obj) => {
        const response = storage;
        obj.forEach((value, key) => {
          response.set(key, value);
        });
        return response;
      }, new Map());
      // console.log("get item list", listOfItems);
      return listOfItems;
    }),
    new Promise((_, reject) => {
      setTimeout(reject, 800);
    })
  ]);

  return list;
};
