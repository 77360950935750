import processItem from "../transformToRecipe";

export default data => {
  const prepareData = data.reduce(
    (prev, current) => {
      const name = current[1].trim();
      if (prev.knownItems.includes(name)) {
        prev.notUniqueItems.push(name);
        return prev;
      }
      prev.knownItems.push(name);
      return prev;
    },
    {
      notUniqueItems: [],
      knownItems: []
    }
  );
  prepareData.notUniqueItems = Array.from(new Set(prepareData.notUniqueItems));
  // console.log("notUniqueItems", prepareData.notUniqueItems);
  return data.reduce(processItem(prepareData.notUniqueItems), new Map());
};
