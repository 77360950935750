import React from "react";
import PropTypes from "prop-types";
import { faCoins, faStar } from "@fortawesome/free-solid-svg-icons";
import Detail from "./Detail";
import Guild from "./Guild";
import Statistic from "./Statistic";

const Recipe = props => {
  const {
    name,
    type,
    recipe,
    guild,
    statistic,
    ingredientOf,
    requiredIngredients
  } = props;
  const structure = {
    name: "Allgemeine Informationen",
    nameIcon: null,
    defaultDisplay: true,
    rows: [
      {
        name: "Herstellungsdauer",
        key: recipe.duration
      },
      {
        name: "Ertragsmenge",
        key: recipe.quantity
      },
      {
        name: "Preis",
        key: recipe.price,
        keyIcon: faCoins
      },
      {
        name: "Verkaufspreis im Stand",
        key: recipe.salesPrice,
        keyIcon: faCoins
      },
      {
        name: "Erfahrung",
        key: recipe.experience,
        keyIcon: faStar
      },
      {
        name: "Herstellungsort",
        key: recipe.building
      },
      {
        name: "Bedarf",
        key: requiredIngredients
      },
      {
        name: "Zutat von",
        key: ingredientOf
      }
    ]
  };

  return (
    <>
      <nav className="level box">
        <div className="level-item-left has-text-left" key={name}>
          <p className=" has-text-weight-semibold">{name}</p>
          <p className="is-family-monospace has-text-grey">{type}</p>
        </div>
      </nav>
      <Detail {...structure} key={structure.name} />
      <Guild {...guild} key="Guild" />
      <Statistic {...statistic} key="Statistic" />
    </>
  );
};

Recipe.defaultProps = {
  ingredientOf: [],
  requiredIngredients: []
};

Recipe.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  requiredIngredients: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  ingredientOf: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),

  recipe: PropTypes.shape({
    duration: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    salesPrice: PropTypes.string.isRequired,
    experience: PropTypes.string.isRequired,
    building: PropTypes.string.isRequired
  }).isRequired,

  statistic: PropTypes.shape({
    profitPerHour: PropTypes.string.isRequired,
    totalProfitPerHour: PropTypes.string.isRequired,
    totalTime: PropTypes.string.isRequired,
    experiencePerHour: PropTypes.string.isRequired
  }).isRequired,

  guild: PropTypes.shape({
    points: PropTypes.string.isRequired,
    pointsPerHour: PropTypes.string.isRequired
  }).isRequired
};

export default Recipe;
