import PapaParse from "papaparse";

export default data => {
  const parser = PapaParse.parse(data, {
    delimiter: ";",
    header: false,
    skipEmptyLines: true
  });
  // console.log(parser.data);
  parser.data.shift();
  parser.data.shift();
  return parser.data;
};
