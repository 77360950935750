export default (knownItems, forItem) => {
  // console.log("findIngredientsOf", knownItems, forItem);
  const filtered = [...knownItems.values()].filter(element => {
    let found = false;
    if (element.ingredients !== undefined && element.ingredients !== null) {
      element.ingredients.forEach(ingredient => {
        if (forItem === ingredient.name) {
          found = true;
        }
      });
    }
    return found;
  });
  if (filtered.length === 0) {
    return [{ name: "---" }];
  }
  return filtered.reduce((stor, element) => {
    return [
      ...stor,
      ...[
        {
          name: `${element.name} (${element.recipe.building.trim()})`,
          link: element.id
        }
      ]
    ];
  }, []);
};
