import React, { useState } from "react";
import PropTypes from "prop-types";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isArray, isObject } from "util";
import { NavLink } from "react-router-dom";

const Recipe = props => {
  // console.log("props of recipe", props);
  const { name, nameIcon, defaultDisplay, rows } = props;

  const [active, setActive] = useState(defaultDisplay);
  const toggleContent = () => setActive(!active);

  const showElement = value => {
    if (isArray(value)) {
      return value.map(element => {
        if (isObject(element)) {
          if (element?.link === undefined) {
            return element.name;
          }
          // console.log(element);
          const link =
            element.ambiguous !== undefined && element.ambiguous === true
              ? `/items/${element.link}`
              : `/itemdetail/${element.link}`;
          const id = `keyList.${element.name}`;
          // return link;
          return (
            <ul className="menu-list" key={id}>
              <NavLink to={link} className="is-paddingless" key={element.name}>
                {element.name}
              </NavLink>
            </ul>
          );
        }
        return element;
      });
    }
    return [value];
  };

  return (
    <>
      <div className="card">
        <div
          className="card-header"
          onClick={toggleContent}
          onKeyDown={toggleContent}
          role="presentation"
        >
          <span className="card-header-title">
            {nameIcon ? <FontAwesomeIcon icon={nameIcon} /> : ""} {name}
          </span>
          <span className="card-header-icon" aria-label="more options">
            <span className="icon">
              <FontAwesomeIcon icon={faAngleDown} />
            </span>
          </span>
        </div>
        <div className={`card-content${active ? "" : " is-hidden"}`}>
          {rows.map(element => (
            <div className="columns" key={element.name}>
              <div className="column is-quarter">
                {element.name}
                {element.nameIcon ? (
                  <FontAwesomeIcon icon={element.nameIcon} />
                ) : (
                  ""
                )}
              </div>
              <div className="column is-three-quarters">
                {showElement(element.key)}
                {element.keyIcon !== undefined ? <>&nbsp;</> : <></>}
                {element.keyIcon !== undefined ? (
                  <FontAwesomeIcon icon={element.keyIcon} />
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

Recipe.defaultProps = {
  nameIcon: null
};

Recipe.propTypes = {
  name: PropTypes.string.isRequired,
  nameIcon: PropTypes.string,
  defaultDisplay: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.isRequired,
      keyIcon: PropTypes.objectOf(PropTypes.any),
      name: PropTypes.string.isRequired,
      nameIcon: PropTypes.objectOf(PropTypes.any)
    })
  ).isRequired
};

export default Recipe;
