import React from "react";
import PropTypes from "prop-types";
import { faStar, faCoins } from "@fortawesome/free-solid-svg-icons";
import Detail from "./Detail";

const AnimalBreeding = ({
  price,
  growthDuration,
  growthBonus,
  growthDurationWithBonus
}) => {
  const structre = {
    name: "Zucht",
    nameIcon: null,
    defaultDisplay: false,
    rows: [
      {
        name: "Zuchtkosten",
        key: price,
        keyIcon: faCoins
      },
      {
        name: "Zuchtdauer",
        key: growthDuration
      },
      {
        name: "Bonus durch Tierpflege",
        key: growthBonus
      },
      {
        name: "Zuchtdauer mit Tierpflege und Krippe",
        key: growthDurationWithBonus
      }
    ]
  };

  return <Detail {...structre} key={structre.name} />;
};

AnimalBreeding.propTypes = {
  durationOfBreeding: PropTypes.string.isRequired,
  growthBonus: PropTypes.string.isRequired,
  growthCount: PropTypes.string.isRequired,
  growthDuration: PropTypes.string.isRequired,
  growthDurationWithBonus: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired
};

export default AnimalBreeding;
