import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getRecipe } from "../../service/Items";
import Recipe from "./Recipe";
import Animal from "./Animal";
import Seed from "./Seed";

const ItemDetail = props => {
  // console.log("props", props);
  const [item, setItem] = useState(null);

  const {
    setGlobalSearchValue,
    match: {
      params: { itemid }
    }
  } = props;

  useEffect(() => {
    setGlobalSearchValue(itemid);
    getRecipe(itemid)
      .then(result => {
        // console.log("recipe", result);
        setItem(result);
      })
      .catch(() => {
        // console.error("something is happened", error);
      });
  }, [itemid]);

  const showDetails = element => {
    // console.log("show details",element);
    if (element.recipe !== null && element.recipe !== undefined)
      return <Recipe {...item} />;
    if (element.animal !== null && element.animal !== undefined)
      return <Animal {...item} />;
    if (element.seed !== null && element.seed !== undefined)
      return <Seed {...item} />;
    return <></>;
  };

  return (
    <>
      {item === null ? (
        <div className="notification is-danger is-light">
          <strong>Es wurden keine Details zu {itemid} gefunden</strong>
        </div>
      ) : (
        showDetails(item)
      )}
    </>
  );
};

ItemDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      itemid: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  // location: PropTypes.objectOf(any).isRequired,
  setGlobalSearchValue: PropTypes.func.isRequired
};

export default ItemDetail;
