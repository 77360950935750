import React from "react";
import PropTypes from "prop-types";
import { faStar, faCoins } from "@fortawesome/free-solid-svg-icons";
import Detail from "./Detail";
import Guild from "./Guild";
import Statistic from "./Statistic";

const Seed = props => {
  const { name, type, seed, guild, statistic, ingredientOf } = props;
  const structure = {
    name: "Allgemeine Informationen",
    nameIcon: null,
    defaultDisplay: true,
    rows: [
      {
        name: "Ertragsmenge",
        key: seed.quantity
      },
      {
        name: "Dauer",
        key: seed.duration
      },
      {
        name: "Preis",
        key: seed.price,
        keyIcon: faCoins
      },
      {
        name: "Verkaufspreis im Stand",
        key: seed.salesPrice,
        keyIcon: faCoins
      },
      {
        name: "Erfahrung Gesamt (Summe)",
        key: seed.experience,
        keyIcon: faStar
      },
      {
        name: "Zeitersparnis pro Gießen",
        key: seed.bonusTime
      },
      {
        name: "XP pro Gießen",
        key: seed.water,
        keyIcon: faStar
      },
      {
        name: "XP pro geerntetes Produkt",
        key: seed.harvest,
        keyIcon: faStar
      },
      {
        name: "XP pro Anpflanzung",
        key: seed.seed,
        keyIcon: faStar
      },
      {
        name: "Zutat von",
        key: ingredientOf
      }
    ]
  };
  return (
    <>
      <nav className="level box">
        <div className="level-item-left has-text-left" key={name}>
          <p className=" has-text-weight-semibold">{name}</p>
          <p className="is-family-monospace has-text-grey">{type}</p>
        </div>
      </nav>
      <Detail {...structure} key={structure.name} />
      <Guild {...guild} key="Guild" />
      <Statistic {...statistic} key="Statistic" />
    </>
  );
};

Seed.defaultProps = {
  ingredientOf: []
};

Seed.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  ingredientOf: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string,
      ambigious: PropTypes.bool
    })
  ),

  seed: PropTypes.shape({
    duration: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,

    price: PropTypes.string.isRequired,
    salesPrice: PropTypes.string.isRequired,

    experience: PropTypes.string.isRequired,

    bonusTime: PropTypes.string.isRequired,
    seed: PropTypes.string.isRequired,
    harvest: PropTypes.string.isRequired,
    water: PropTypes.string.isRequired,

    purchase: PropTypes.string.isRequired,

    indredientOf: PropTypes.string
  }).isRequired,

  statistic: PropTypes.shape({
    profitPerHour: PropTypes.string.isRequired,
    totalProfitPerHour: PropTypes.string.isRequired,
    totalTime: PropTypes.string.isRequired,
    experiencePerHour: PropTypes.string.isRequired
  }).isRequired,

  guild: PropTypes.shape({
    points: PropTypes.string.isRequired,
    pointsPerHour: PropTypes.string.isRequired
  }).isRequired
};

export default Seed;
