// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { Subject } from "rxjs";
import PropTypes from "prop-types";
import { filter, debounceTime, switchMap } from "rxjs/operators";
import { getItemList as getItemsForSuggests } from "../../service/Items";

const SearchFieldRx = ({
  suggestions,
  globalSearchValue,
  setGlobalSearchValue,
  setSuggestions
}) => {
  const [searchKey, setSearchKey] = useState("");
  const [isActive, setActive] = useState(false);
  const searchValueRef = useRef();
  const wrapperRef = useRef(null);
  const history = useHistory();

  function onChangeSearchKey(e) {
    setSearchKey(e.target.value);
    searchValueRef.current.next(e.target.value);
  }

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      // event.preventDefault();
      // event.stopPropagation();
      setActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    searchValueRef.current = new Subject();
    const subscription = searchValueRef.current;
    subscription
      .pipe(
        debounceTime(250),
        filter(text => {
          return text.length > 1;
        }),
        switchMap(keyword => {
          return getItemsForSuggests(keyword).then(result => {
            // console.log("suggest",result);
            return result;
          });
        })
      )
      .subscribe(data => {
        setSuggestions(Array.from(data.values()));
        setActive(true);
      });

    subscription
      .pipe(
        filter(text => {
          return text.length < 2;
        })
      )
      .subscribe(() => {
        setSuggestions([]);
        setActive(false);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setSearchKey(globalSearchValue);
    setActive(false);
  }, [globalSearchValue]);

  const submitSearch = event => {
    if (event.key === "Enter") {
      setGlobalSearchValue(searchKey);
      history.push(`/items/${encodeURI(searchKey)}`);
    }
  };

  return (
    <div
      ref={wrapperRef}
      className={`navbar-item has-dropdown${
        isActive && suggestions.length ? " is-active" : ""
      }`}
    >
      <input
        placeholder="suche nach Tieren / Rezepten / Gebäude"
        value={searchKey}
        onClick={() => searchValueRef.current.next(searchKey)}
        onChange={onChangeSearchKey}
        className="input is-small navbar-link"
        onKeyPress={submitSearch}
      />
      {isActive && (
        <div className="navbar-dropdown is-boxed">
          {suggestions.map(Item => {
            // console.log("render list entry", Item);
            return (
              <Link
                to={`/items/${encodeURI(Item.name)}`}
                key={Item.id}
                className="navbar-item"
              >
                {Item.name}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

SearchFieldRx.propTypes = {
  setGlobalSearchValue: PropTypes.func.isRequired,
  setSuggestions: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  globalSearchValue: PropTypes.string.isRequired
};

export default SearchFieldRx;
