import filterInvalidRecipe from "../filterInvalidRecipe";
import processList from "../list/processList";
import csvParser from "../csvParser";

const RecipesResources = require("../../resources/Recipes.csv");

export default () => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", RecipesResources);
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        let data = csvParser(xhr.response);
        data = filterInvalidRecipe(data);
        data = processList(data);
        // console.log("data", data);
        // data = exactFilter(data, value);
        resolve(data);
      } else {
        reject(xhr.statusText);
      }
    };
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send();
  });
};
