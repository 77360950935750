import csvParser from "../csvParser";
import filterInvalidRecipe from "../filterInvalidRecipe";
import processList from "./processList";

const RecipesResources = require("../../resources/Recipes.csv");

const regExFilter = (recipies, value) => {
  // console.log("recipies",recipies);

  recipies.forEach((reciepe, key) => {
    RegExp.escape = s => {
      return s.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
    };
    const regExString = RegExp.escape(value);
    // console.log("regex", regExString);
    const regEx = new RegExp(regExString, "i");
    if (!regEx.test(reciepe.name) && !regEx.test(reciepe.building)) {
      // console.log("delete recipe", reciepe.name, reciepe);
      recipies.delete(key);
    }
  });
  // console.log("filtered reciepes", recipies);
  return recipies;
};

export default async value => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", RecipesResources);
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        let data = csvParser(xhr.response);
        data = filterInvalidRecipe(data);
        data = processList(data);
        // console.log("data", data);
        data = regExFilter(data, value);
        resolve(data);
      } else {
        reject(xhr.statusText);
      }
    };
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send();
  });
};
