import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

const Recipe = ({ id, name, type }) => {
  const getLinkTo = () => `/itemdetail/${encodeURI(id)}`;

  return (
    <div className="level box">
      <Link to={getLinkTo()} className="is-paddingless">
        <div className="level-item-left has-text-left">
          <p className=" has-text-weight-semibold">{name}</p>
          <p className="is-family-monospace has-text-grey">{type}</p>
        </div>
      </Link>
    </div>
  );
};

Recipe.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default Recipe;
