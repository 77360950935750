import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from "./component/App";

const StartPage = () => {
  return (
    <Router>
      <Route path="/" component={App} />
    </Router>
  );
};

ReactDOM.render(<StartPage />, document.getElementById("root"));
