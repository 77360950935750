import React from "react";
import PropTypes from "prop-types";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import Detail from "./Detail";
import AnimalIndex from "./AnimalIndex";
import AnimalSlaughter from "./AnimalSlaughter";
import AnimalBreeding from "./AnimalBreeding";

const Animal = props => {
  const { name, type, animal, ingredientOf } = props;
  const structure = {
    name: "Allgemeine Informationen",
    nameIcon: null,
    defaultDisplay: true,
    rows: [
      {
        name: "Kaufpreis",
        key: animal.price.purchase,
        keyIcon: faCoins
      },
      {
        name: "Verkaufspreis",
        key: animal.price.price,
        keyIcon: faCoins
      },
      {
        name: "Zutat von",
        key: ingredientOf
      }
    ]
  };
  return (
    <>
      <nav className="level box">
        <div className="level-item-left has-text-left" key={name}>
          <p className=" has-text-weight-semibold">{name}</p>
          <p className="is-family-monospace has-text-grey">{type}</p>
        </div>
      </nav>
      <Detail {...structure} key={structure.name} />
      <AnimalBreeding {...animal.breeding} key="Animal Breeding" />
      <AnimalSlaughter {...animal.slaughter} key="Animal Slaughter" />
      <AnimalIndex {...animal.index} key="Animal Statistic" />
    </>
  );
};

Animal.defaultProps = {
  ingredientOf: []
};

Animal.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  ingredientOf: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string,
      ambigious: PropTypes.bool
    })
  ),

  animal: PropTypes.shape({
    price: PropTypes.shape({
      price: PropTypes.string.isRequired,
      purchase: PropTypes.string.isRequired
    }).isRequired,
    index: PropTypes.shape({
      good: PropTypes.string.isRequired,
      slaughter: PropTypes.string.isRequired,
      total: PropTypes.string.isRequired
    }).isRequired,
    goods: PropTypes.shape({
      numberOfGoods: PropTypes.string.isRequired,
      quantityOfGoods: PropTypes.string.isRequired
    }).isRequired,
    slaughter: PropTypes.shape({
      duration: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired,
    breeding: PropTypes.shape({
      durationOfBreeding: PropTypes.string.isRequired,
      growthBonus: PropTypes.string.isRequired,
      growthCount: PropTypes.string.isRequired,
      growthDuration: PropTypes.string.isRequired,
      growthDurationWithBonus: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,

  statistic: PropTypes.shape({
    profitPerHour: PropTypes.string.isRequired,
    totalProfitPerHour: PropTypes.string.isRequired,
    totalTime: PropTypes.string.isRequired,
    experiencePerHour: PropTypes.string.isRequired
  }).isRequired,

  guild: PropTypes.shape({
    points: PropTypes.string.isRequired,
    pointsPerHour: PropTypes.string.isRequired
  }).isRequired
};

export default Animal;
