import React from "react";
import PropTypes from "prop-types";
import Detail from "./Detail";
import { faStar, faCoins } from "@fortawesome/free-solid-svg-icons";

const AnimalSlaughter = ({ duration, value }) => {
  const structre = {
    name: "Schlachthaus",
    nameIcon: null,
    defaultDisplay: false,
    rows: [
      {
        name: "Schlachtdauer",
        key: duration
      },
      {
        name: "Warenwert beim Schlachten",
        key: value,
        keyIcon: faCoins
      }
    ]
  };

  return <Detail {...structre} key={structre.name} />;
};

AnimalSlaughter.propTypes = {
  duration: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default AnimalSlaughter;
