import React from "react";
import PropTypes from "prop-types";
import Detail from "./Detail";

const AnimalIndex = ({ good, total, slaughter }) => {
  const structre = {
    name: "Statistik",
    nameIcon: null,
    defaultDisplay: false,
    rows: [
      {
        name: "Gesamtindex",
        key: total
      },
      {
        name: "Schlachtindex",
        key: slaughter
      },
      {
        name: "Warenindex",
        key: good
      }
    ]
  };

  return <Detail {...structre} key={structre.name} />;
};

AnimalIndex.propTypes = {
  good: PropTypes.string.isRequired,
  slaughter: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired
};

export default AnimalIndex;
